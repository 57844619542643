/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from '../../../../layouts/PageContent';
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import InstructionsCard from '../../../../components/Cards/InstructionsCard/InstructionsCard';
import FieldTextArea from '../../../../components/Controls/FieldTextArea.jsx';
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import { Divider } from 'primereact/divider';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import { ConstantLineStyle } from 'devextreme-react/polar-chart';
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator';
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from '../../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import ScoreCardSummaryDet from 'views/HR/PerformanceDevelopment/AnnualReview/ScoreCardSummaryDet';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import FieldNumber from 'components/Controls/FieldNumber';

const EmployeeSelfReview = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);

  const defaultAnio = props.Anio !== undefined ? props.Anio : null;
  const defaultAnioCriteria = 2024;
  const defaultClaUsuario = props.ClaUsuario !== undefined ? props.ClaUsuario : null;

  const usuarioConectado = getSessionItem('ClaUsuarioMod', 0);

  const [selfReviewFeedback, setSelfReviewFeedback] = useState([]);
  const [SelfReviewCriteria, setSelfReviewCriteria] = useState([]);
  const [SelfReviewCriteriaScale, setSelfReviewCriteriaScale] = useState([]);
  const [commentAvailable, setCommentAvailable] = useState(false);
  const [selfReviewAvailable, setSelfReviewAvailable] = useState(false);
  const [esInformacionDisponible, setEsInformacionDisponible] = useState(false);
  const [estatusEmployeeSelfReview, setEstatusEmployeeSelfReview] = useState(0);
  const [Comentarios, setComentarios] = useState(null);
  const [EmpleadoDesempenioEntregablesAnioRevision, setEmpleadoDesempenioEntregablesAnioRevision] = useState(null);
  const [EmpleadoDesempenioMejorasSiguienteAnio, setEmpleadoDesempenioMejorasSiguienteAnio] = useState(null);
  const [esTienePermisoEdicion, setEsTienePermisoEdicion] = useState(0);
  const [errors, setErrors] = useState({});
  const [idPerformanceDev, setIdPerformanceDev] = useState(null);
  const [fechaIniciaEmployeeSelfReview, setFechaIniciaEmployeeSelfReview] = useState(null);
  const [fechaTerminaEmployeeSelfReview, setFechaTerminaEmployeeSelfReview] = useState(null);
  const [fechaIniciaAnnualReview, setFechaIniciaAnnualReview] = useState(null);
  const [AvgTotal, setAvgTotal] = useState(0);
  const [EsDesactivaUpdateButton, setEsDesactivaUpdateButton] = useState(0);
  const [EsVersionSoloLectura, setEsVersionSoloLectura] = useState(false);
  const [EstatusAnnualReview, setEstatusAnnualReview] = useState(0);

  useEffect(() => {
    loadEmployeeSelfReview();
  }, [props.Anio, props.ClaUsuario]);

  const loadEmployeeSelfReview = () => {
    const GetEmployeeSelfReviewUser = `${config.UrlApiProject}hr/GetEmployeeSelfReviewUser`;
    const paramsToService = {
      ClaUsuario: defaultClaUsuario,
      Anio: defaultAnio,
    };

    callApi(GetEmployeeSelfReviewUser, 'POST', paramsToService, (response) => {
      setSelfReviewFeedback(response.data.EmployeeSelfReview);

      setSelfReviewAvailable(response.data.EmployeeSelfReview.length > 0);
      setSelfReviewCriteria(response.data.EmployeeSelfReviewCriteria);

      if (response.data.Encabezado.length > 0) {
        setEmpleadoDesempenioEntregablesAnioRevision(response.data.Encabezado[0].EmpleadoDesempenioEntregablesAnioRevision);
        setEmpleadoDesempenioMejorasSiguienteAnio(response.data.Encabezado[0].EmpleadoDesempenioMejorasSiguienteAnio);
        setAvgTotal(obtienePromedio(response.data.EmployeeSelfReviewCriteria));
        setComentarios(response.data.Encabezado[0].Comentarios);
        setCommentAvailable(response.data.Encabezado[0].EstatusEmployeeSelfReview === 1);
        setEsInformacionDisponible(response.data.Encabezado[0].ActivaEmployeeSelfReview);
        setEstatusEmployeeSelfReview(response.data.Encabezado[0].EstatusEmployeeSelfReview);
        setIdPerformanceDev(response.data.Encabezado[0].IdPerformanceDev);
        setFechaIniciaEmployeeSelfReview(response.data.Encabezado[0].FechaIniciaEmployeeSelfReview);
        setFechaTerminaEmployeeSelfReview(
          response.data.Encabezado[0].FechaTerminaEmployeeSelfReview
        );
        setFechaIniciaAnnualReview(response.data.Encabezado[0].FechaIniciaAnnualReview);
        setEsDesactivaUpdateButton(response.data.Encabezado[0].EsDesactivaUpdateButton);
        setEstatusAnnualReview(response.data.Encabezado[0].EstatusAnnualReview);
      }

      setEsTienePermisoEdicion(defaultClaUsuario === usuarioConectado);
      setErrors({});

      // varificamos si es solo lectura....
      if (typeof props.ReadOnlyView !== 'undefined') {
        setEsVersionSoloLectura(props.ReadOnlyView);
      }
      setSelfReviewCriteriaScale([
          {
              Scale: t('hr:ScaleValueOutstanding'),
              PerformanceRating: t('hr:ScaleOutstanding')
          },
          {
            Scale: t('hr:ScaleValueExceeds'),
            PerformanceRating: t('hr:ScaleExceeds')
          },
          {
            Scale: t('hr:ScaleValueMeets'),
            PerformanceRating: t('hr:ScaleMeets')
          },
          {
            Scale: t('hr:ScaleValueNeedsImprovement'),
            PerformanceRating: t('hr:ScaleNeedsImprovement')
          },
          {
            Scale: t('hr:ScaleValueUnsatisfactory'),
            PerformanceRating: t('hr:ScaleUnsatisfactory')
        }
      ]);


    });
  };

  const onChangeComentarios = (e) => {
    const newErrors = { ...errors };
    delete newErrors.Comentarios;
    setErrors(newErrors);
    setComentarios(e.target.value);
  };

  const onChangeEmpleadoDesempenioEntregablesAnioRevision = (e) => {
    const newErrors = { ...errors };
    delete newErrors.EmpleadoDesempenioEntregablesAnioRevision;
    setErrors(newErrors);
    setEmpleadoDesempenioEntregablesAnioRevision(e.target.value);
  };

  const onChangeEmpleadoDesempenioMejorasSiguienteAnio = (e) => {
    const newErrors = { ...errors };
    delete newErrors.EmpleadoDesempenioMejorasSiguienteAnio;
    setErrors(newErrors);
    setEmpleadoDesempenioMejorasSiguienteAnio(e.target.value);
  };

  const onChangeRespuesta = (e, index) => {
    const updatedSelfReviewFeedback = [...selfReviewFeedback];
    updatedSelfReviewFeedback[index].RespuestaPregunta = e.target.value;

    if (e.target.value.length > 0) {
      updatedSelfReviewFeedback[index].errors = null;
      const newErrors = { ...errors };
      const field = { ...newErrors[`selfReviewFeedback[${index}]`] };
      field.RespuestaPregunta = null;
      newErrors[`selfReviewFeedback[${index}]`] = field;
      setErrors(newErrors);
    }

    setSelfReviewAvailable(updatedSelfReviewFeedback);
  };

  const valitationScheme = yup.object().shape({
    selfReviewFeedback: yup.array().of(
      yup.object().shape({
        RespuestaPregunta: yup.string().trim().required(t('common:RequiredField')).nullable(),
      })
    ), 
    SelfReviewCriteria: (
      defaultAnio>=defaultAnioCriteria
      ?  yup.array().of(
        yup.object().shape({
          CalificacionEmpleado: yup.mixed().required(t('common:RequiredField')).nullable(),
        })
      )
      : yup.array().of(
        yup.object().shape({
          CalificacionEmpleado: yup.mixed().nullable(),
        })
      )
    ), 
    EmpleadoDesempenioMejorasSiguienteAnio: yup.string().trim().nullable()
      .when([], {
        is: () => defaultAnio >= defaultAnioCriteria,
        then: schema => schema.required(t('common:RequiredField'))
      }),
    EmpleadoDesempenioEntregablesAnioRevision: yup.string().trim().nullable()
      .when([], {
        is: () => defaultAnio >= defaultAnioCriteria,
        then: schema => schema.required(t('common:RequiredField'))
      }),
  });

  const valitationSchemeComentarios = yup.object().shape({
    Comentarios: yup.string().trim().required(t('common:RequiredField')).nullable(),
  });

  const confirmationMessageHTML = (
    <div>
      <SweatAlertCustomIcon
        title={t('common:SubmitConfirmation')}
        letter={t('common:QuestionMark')}
      >
      </SweatAlertCustomIcon>
      <div>
        {t('hr:SubmitResponseConfirmation')}. <b>{t('common:ThanksParticipation')}</b>
      </div>
    </div>
  );

  const onConfirmSubmit = async () => {
    const value = {
      selfReviewFeedback,
      SelfReviewCriteria,
      EmpleadoDesempenioEntregablesAnioRevision,
      EmpleadoDesempenioMejorasSiguienteAnio
    };
    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);
    if (!esValido) {
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      setErrors(validator.errors);
      return;
    }
    swal({
      buttons: {
        cancelar: {
          text: 'Cancel',
          value: 'cancel',
          className: 'rounded-button danger',
        },
        continuar: {
          text: 'Continue',
          value: 'continue',
          className: 'rounded-button light-green',
        },
      },
      content: confirmationMessageHTML,
    }).then((value) => {
      switch (value) {
        case 'continue':
          // run function
          onSubmit();
          break;
        default:
          break;
      }
    });
  };

  const onSave = () => {
    const paramsService = {
      IdPerformanceDev: idPerformanceDev,
      Anio: defaultAnio,
      ClaUsuarioPerformanceDev: defaultClaUsuario,
      ComentariosEmployeeSelfReview: Comentarios,
      PerformanceDevQuestionarioResuelto: selfReviewFeedback,
      EstatusEmployeeSelfReview: 0,
      PerformanceDevCriterioCalificacion: SelfReviewCriteria,
      EmpleadoDesempenioEntregablesAnioRevision,
      EmpleadoDesempenioMejorasSiguienteAnio
    };
    const SaveEmployeeSelfReview = `${config.UrlApiProject}HR/SaveEmployeeSelfReview`;
    callApi(SaveEmployeeSelfReview, 'POST', paramsService, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      loadEmployeeSelfReview();
    });
  };

  const onUpdateClick = async () => {
    const value = {
      Comentarios,
    };

    const validator = new YupValidator(valitationSchemeComentarios);
    const esValido = await validator.validate(value);

    console.log(validator.errors);
    if (!esValido) {
      setErrors(validator.errors);
      return;
    }

    const paramsService = {
      IdPerformanceDev: idPerformanceDev,
      Anio: defaultAnio,
      ClaUsuarioPerformanceDev: defaultClaUsuario,
      ComentariosEmployeeSelfReview: Comentarios,
    };

    const SaveEmployeeSelfReview = `${config.UrlApiProject}HR/UpdateEmployeeSelfReviewComments`;

    callApi(SaveEmployeeSelfReview, 'POST', paramsService, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      loadEmployeeSelfReview();
    });
  };

  const onSubmit = async () => {
    const value = {
      selfReviewFeedback,
      SelfReviewCriteria,
      EmpleadoDesempenioEntregablesAnioRevision,
      EmpleadoDesempenioMejorasSiguienteAnio

    };
    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);
    if (!esValido) {
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      setErrors(validator.errors);
      return;
    }
    const paramsService = {
      IdPerformanceDev: idPerformanceDev,
      Anio: defaultAnio,
      ClaUsuarioPerformanceDev: defaultClaUsuario,
      ComentariosEmployeeSelfReview: Comentarios,
      PerformanceDevQuestionarioResuelto: selfReviewFeedback,
      EstatusEmployeeSelfReview: 1,
      PerformanceDevCriterioCalificacion: SelfReviewCriteria,
      EmpleadoDesempenioEntregablesAnioRevision,
      EmpleadoDesempenioMejorasSiguienteAnio
    };

    const SaveEmployeeSelfReview = `${config.UrlApiProject}HR/SaveEmployeeSelfReview`;

    callApi(SaveEmployeeSelfReview, 'POST', paramsService, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      loadEmployeeSelfReview();
    });
  };

  if (!esInformacionDisponible) {
    return (
      <>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-center p-7">
            <h2>{t('hr:SelftReviewPeriodClosed')}</h2>
          </Col>
        </Row>
      </>
    );
  }

  if (!esTienePermisoEdicion && estatusEmployeeSelfReview === 0) {
    return (
      <>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-center p-7">
            <h2>{t('hr:EmployeeSelfReviewInstructionsBeforeSubmitManager')}</h2>
          </Col>
        </Row>
      </>
    );
  }

  const fieldsRequired = (rowData) =>  { 
    return (
      <>
        {errors[`SelfReviewCriteria[${rowData.RowIndex}]`] != null ? (<i className="fas fa-exclamation-triangle invalid-color" title={t('hr:EmployeeSelfReviewCriteriaRequired')}></i>) : ""}
      </>
    );
  } 

  const calificacionEmpleadoByEditor = (props, rowData) => {
    return (
      <div className="p-fluid">
        <FieldNumber   
          mode="decimal"
          locale="en-US"
          value={props.rowData.CalificacionEmpleado} 
          minFractionDigits={1}
          maxFractionDigits={1}
          min={0}
          max={5}
          onChange={(e) => onCalificacionEmpleadoByChange(props, e.target.value, rowData)} 
        />  
      </div>
      
    );
  }
  
const onCalificacionEmpleadoByChange = (props, value ) => {  
  const updatedRows = [...SelfReviewCriteria];
  updatedRows[props.rowIndex].CalificacionEmpleado = value;
  updatedRows[props.rowIndex].errors = null;
  const newErrors = { ...errors };
  const fieldKey = `SelfReviewCriteria[${props.rowIndex}]`;
  if (value) {
    if (newErrors[fieldKey]) {
      delete newErrors[fieldKey].CalificacionEmpleado; 
      if (Object.keys(newErrors[fieldKey]).length === 0) {
        delete newErrors[fieldKey];
      }
    }
  } else { 
    if (!newErrors[fieldKey]) {
      newErrors[fieldKey] = {};
    }
    newErrors[fieldKey].CalificacionEmpleado = null;
  }
  setErrors(newErrors);
  setSelfReviewCriteria(updatedRows); 
  setAvgTotal(obtienePromedio(updatedRows));
  
}; 

function obtienePromedio(lSelfReviewCriteria) {
  const calificaciones = lSelfReviewCriteria
      .map(row => row.CalificacionEmpleado)
      .filter(val => val !== null && val !== undefined && !isNaN(val));

  if (calificaciones.length === 0) {
      return 0;  
  }
  const nAvgTotal = calificaciones.reduce((acc, curr) => acc + curr, 0) / calificaciones.length;
  const roundedAvgTotal = parseFloat(nAvgTotal.toFixed(2));
  return roundedAvgTotal;
}


  return (
    <>
     
      {defaultAnio > 2021 ? (
        <>
          <ScoreCardSummaryDet
            Anio={defaultAnio}
            ClaUsuario={defaultClaUsuario}
          >
          </ScoreCardSummaryDet>
        </>
      ) : (
        <></>
      )} 
      {EsVersionSoloLectura ? (
        <></>
      ) : (
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <InstructionsCard>
              {esTienePermisoEdicion ? (
                estatusEmployeeSelfReview === 0 ? (
                  <p>
                    {t('hr:EmployeeSelfReviewInstructionsBeforeSubmit')}{' '}
                    <b className="highlight-text">
                      {' '}
                      {moment(fechaIniciaEmployeeSelfReview).format('LL')} -{' '}
                      {moment(fechaTerminaEmployeeSelfReview).format('LL')}
                    </b>
                    .
                  </p>
                ) : (
                  <p>
                    <span className="highlight-text">
                      {t('hr:ThanksPortionAnnualReviewPrework')}
                    </span>{' '}
                    {
                      /*
                      {t('hr:EmployeeSelfReviewInstructionsAfterSubmit')}{' '}
                      <span className="highlight-text">
                        {' '}
                        {moment(fechaIniciaAnnualReview).format('LL')}{' '}
                      </span>
                      */
                    }
                    
                    .
                  </p>
                )
              ) : estatusEmployeeSelfReview === 0 ? (
                <p>{t('hr:EmployeeSelfReviewInstructionsBeforeSubmitManager')}.</p>
              ) : (
                <p>{t('hr:EmployeeSelfReviewInstructionsAfterSubmitManager')}.</p>
              )}
            </InstructionsCard>
          </Col>
        </Row>
      )}
      {
        esTienePermisoEdicion &&
        (
          <>
            <Row id="EmployeeRating" hidden={defaultAnio < defaultAnioCriteria}>
              <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
              </Col>
            </Row>
            <Row id="EmployeeRating" hidden={defaultAnio < defaultAnioCriteria}>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Card> 
                  <CardBody className="">
                    <Row className="align-items-start">
                      <Col lg={8} md={8} sm={12} xs={12} className="text-center"> 
                        <div>
                          <h1>{t('hr:EmployeePerformanceRating')}</h1>
                        </div> 
                      </Col>
                      <Col lg={4} md={4} sm={12} xs={12} className="text-center"> 
                        <div>
                          <h1>{t('hr:PerformanceRatingScale')}</h1>
                        </div> 
                      </Col>
                    </Row>
                    <Row className="align-items-start h-100">  
                      <Col lg={8} md={8} sm={12} xs={12} className="h-100">  
                        <FieldDataTable 
                          value={SelfReviewCriteria} 
                          editMode={estatusEmployeeSelfReview > 0?null:"cell"} 
                          className="editable-cells-table"
                          paginator={false}
                        > 
                          <Column body={fieldsRequired} headerStyle={{ width: '20px' }}></Column>
                          <Column 
                            field="TituloCriterio" 
                            columnKey="TituloCriterio" 
                            header={t('hr:PerformanceCriteria')} 
                            headerStyle={{ width: '70%' }} 
                            footer={t('hr:Total')}
                          >
                          </Column> 
                          <Column
                            field="CalificacionEmpleado" 
                            header={t('hr:EmployeeRating')} 
                            headerStyle={{ width: '30%', textAlign: 'center' }} 
                            style={{ textAlign: 'center' }}  
                            editor={estatusEmployeeSelfReview > 0 ? null : (props) => calificacionEmpleadoByEditor(props)} 
                            footer={AvgTotal}
                          >
                          </Column>   
                        </FieldDataTable>
                      </Col>  
                      <Col lg={4} md={4} sm={12} xs={12} className="h-100">  
                        <FieldDataTable 
                          value={SelfReviewCriteriaScale}  
                          paginator={false}
                          className='table-header-background-ligth-green'
                        >  
                          <Column 
                            field="Scale" 
                            columnKey="Scale" 
                            header={t('hr:Scale')} 
                            headerStyle={{ width: '20%',textAlign: 'center' }} 
                            style={{ textAlign: 'center' }} 
                            className="table-header-background-ligth-green"
                          >
                          </Column> 
                          <Column
                            field="PerformanceRating" 
                            columnKey="PerformanceRating" 
                            header={t('hr:PerformanceRating')} 
                            headerStyle={{ width: '80%'}}  
                            className="table-header-background-ligth-green"
                          >
                          </Column>   
                        </FieldDataTable>
                      </Col>  
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )
      }
      
      <Row id="EmployeePerformanceReview" hidden={defaultAnio < defaultAnioCriteria}>
        <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
        </Col>
      </Row>
      <Row id="EmployeePerformanceReview" hidden={defaultAnio < defaultAnioCriteria} className="align-items-start mb-4">
        <Col md={12} sm={12} xs={12}>
          <Card>
            <CardHeader className="light-blue">
              <CardTitle className="text-center p-2">{t('hr:EmployeePerformanceReview')}</CardTitle>
            </CardHeader>
            <CardBody className="questionario-viewer">
              {estatusEmployeeSelfReview === 1 ||
                      esTienePermisoEdicion === false ||
                      EsVersionSoloLectura === true ||
                      EstatusAnnualReview === 1 ? (
                        <>
                          <div>
                            <div className="Pregunta">
                              <label>
                                <b>1.- {t('hr:EmployeePerformanceReviewQuestion1')} {defaultAnio}. {t('hr:EmployeePerformanceReviewQuestion1-2')}</b>
                              </label>
                            </div>
                            <div className="p-fluid">
                              <label>{EmpleadoDesempenioEntregablesAnioRevision}</label>
                            </div>
                          </div>
                          <div>
                            <div className="Pregunta">
                              <label>
                                <b>2.- {t('hr:EmployeePerformanceReviewQuestion2')} {defaultAnio} {t('hr:EmployeePerformanceReviewQuestion2-2')}</b>
                              </label>
                            </div>
                            <div className="p-fluid">
                              <label>{EmpleadoDesempenioMejorasSiguienteAnio}</label>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="Pregunta">
                              <label>
                                <b>1.- {t('hr:EmployeePerformanceReviewQuestion1')} {defaultAnio}. {t('hr:EmployeePerformanceReviewQuestion1-2')}</b>
                              </label>
                            </div>
                            <div className="p-fluid">
                              <FieldTextArea
                                name="EmpleadoDesempenioEntregablesAnioRevision"
                                rows={3}
                                placeholder=""
                                value={EmpleadoDesempenioEntregablesAnioRevision}
                                maxLength={3000}
                                onChange={onChangeEmpleadoDesempenioEntregablesAnioRevision}
                                errors={errors}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="Pregunta">
                              <label>
                                <b>2.- {t('hr:EmployeePerformanceReviewQuestion2')} {defaultAnio} {t('hr:EmployeePerformanceReviewQuestion2-2')}</b>
                              </label>
                            </div>
                            <div className="p-fluid">
                              <FieldTextArea
                                name="EmpleadoDesempenioMejorasSiguienteAnio"
                                rows={3}
                                placeholder=""
                                value={EmpleadoDesempenioMejorasSiguienteAnio}
                                maxLength={3000}
                                onChange={onChangeEmpleadoDesempenioMejorasSiguienteAnio}
                                errors={errors}
                              />
                            </div>
                          </div>
                        </>
                      )}
             
            </CardBody>
          </Card>
        </Col>
       
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
        </Col>
      </Row>
      <Row className="align-items-start mb-4">
        <Col
          md={commentAvailable ? 8 : 12}
          sm={12}
          xs={12}
          style={{ display: selfReviewAvailable ? 'block' : 'none' }}
        >
          <Card>
            <CardHeader className="light-blue">
              <CardTitle className="text-center p-2">{t('hr:EmployeeDevelopment')}</CardTitle>
            </CardHeader>
            <CardBody className="questionario-viewer">
              {selfReviewFeedback.map((element, index) => {
                return (
                  <div key={index}>
                    {index > 0 ? <Divider></Divider> : <></>}
                    <div className="Pregunta">
                      <label>
                        <b>{element.DescripcionPregunta}</b>
                      </label>
                    </div>
                    <div className="Respuesta">
                      {estatusEmployeeSelfReview === 1 ||
                      esTienePermisoEdicion === false ||
                      EsVersionSoloLectura === true ||
                      EstatusAnnualReview === 1 ? (
                        <>
                          <label>{element.RespuestaPregunta}</label>
                        </>
                      ) : (
                        <>
                          <div className="p-fluid">
                            <FieldTextArea
                              name="RespuestaPregunta"
                              rows={3}
                              placeholder=""
                              value={element.RespuestaPregunta}
                              maxLength={3000}
                              onChange={(e) => {
                                onChangeRespuesta(e, index);
                              }}
                              errors={errors[`selfReviewFeedback[${index}]`]}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
              {EsVersionSoloLectura || EstatusAnnualReview === 1 ? (
                <></>
              ) : (
                <div
                  className="w-100 text-center"
                  style={{ display: esTienePermisoEdicion ? 'block' : 'none' }}
                >
                  {estatusEmployeeSelfReview === 0 ? (
                    <FieldButton
                      label="Save"
                      className="secondary no-border rounded-button p-3 pl-5 pr-5  font-weight-bold"
                      onClick={onSave}
                    />
                  ) : (
                    <></>
                  )}
                  &nbsp;
                  {estatusEmployeeSelfReview === 0 ? (
                    <FieldButton
                      label="Submit"
                      className="light-green no-border rounded-button p-3 pl-5 pr-5  font-weight-bold"
                      onClick={onConfirmSubmit}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col
          lg={4}
          md={4}
          sm={12}
          xs={12}
          className="text-center"
          style={{ display: commentAvailable ? 'block' : 'none' }}
        >
          <Card>
            <CardBody>
              <Row className="align-items-start">
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div>
                    <label>
                      <b>{t('hr:Comments')}</b>
                    </label>
                  </div>
                  {esTienePermisoEdicion &&
                  EsDesactivaUpdateButton === 0 &&
                  EsVersionSoloLectura === false &&
                  EstatusAnnualReview === 0 ? (
                    <div className="p-fluid">
                      <FieldTextArea
                        name="Comentarios"
                        rows={8}
                        placeholder=""
                        value={Comentarios}
                        onChange={onChangeComentarios}
                        errors={errors}
                      />
                    </div>
                  ) : (
                    <label className="text-left">{Comentarios}</label>
                  )}
                  {EsVersionSoloLectura || EstatusAnnualReview === 1 ? (
                    <></>
                  ) : (
                    <div
                      style={{
                        display:
                          esTienePermisoEdicion && EsDesactivaUpdateButton === 0 ? 'block' : 'none',
                      }}
                    >
                      <FieldButton
                        label={t('common:Update')}
                        className="light-green no-border rounded-button p-3 pl-5 pr-5  font-weight-bold"
                        onClick={onUpdateClick}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </>
  );
};

export default EmployeeSelfReview;
