/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col, 
  Card,
  CardHeader,
  CardBody 
} from 'reactstrap'; 
// import FeedbackCoaching from './FeedbackCoaching.jsx'; 
import Highcharts from 'highcharts';
import { config } from '../../../utils/config';
import { callApi,showSweetAlert } from '../../../utils/utils';   
import 'devextreme/dist/css/dx.light.css';  
import DailyOperationProductionTrendSkeleton from './MonthlyProductionSummarySkeleton'; 
import { Column } from 'primereact/column';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import moment from "moment"; 

const MonthlyProductionSummaryDetail = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['wtw', 'common']);  
  const [IsLoading, setIsLoading] = useState(false);  
  const [Detalle, setDetalle] = useState([]);
  const [Downtime, setDowntime] = useState([]);
  const [Comentarios, setComentarios] = useState([]);
  
  useEffect(() => {
    console.log('MonthlyProductionSummaryDetail props',props); 
    getTredDetail(); 
  }, []);  


  const CajasTemplate = (item, columnName, decimals) => {
    return (
      <> 
        <span>{Highcharts.numberFormat(item[columnName],decimals, '.', ',')} </span>
      </>
      );
  };  
  
  const DecimalTemplate = (item, columnName, decimals) => {
    
    return (
      <> 
        <span>{Highcharts.numberFormat(item[columnName],decimals, '.', ',')}</span>
      </>
      );
  };  
  
  const HorasParoTemplate = (item) => {
    
    return (
      <> 
        <span>{Highcharts.numberFormat(item.HorasParo,2, '.', ',')}</span>&nbsp;
        <span>({item.ConteoParos})</span>
      </>
      );
  };  
  const PorcentajeTemplate = (item, columnName) => {
    
    return (
      <> 
        <span>{Highcharts.numberFormat(item[columnName],2, '.', ',')}%</span>
      </>
      );
  };  

  const DateTemplate = (item, columnName) => {
    const dateValue = item[columnName];
    return (
      <> 
        <span> 
          <span>
            { dateValue ? moment(dateValue).format('MMMM DD, YYYY') : '' }
          </span>
        </span>
      </>
      );
  };  

  
  const NomArticuloTemplate = (item) => { 
    return (
      <> 
        <span>   
          {item.ClaveArticulo} - {item.NomArticulo}
        </span>
      </>
      );
  };  

  

  const getTredDetail = () => {  
    const urlWebService = `${config.UrlApiProject}WTW/GetProduccionSeguimientoTendenciaDiariaDetalle`; 
    const paramsService = {
      ClaUbicacion:props.ClaUbicacion,
      ClaAnio:props.Anio, 
      ClaMes:props.Mes, 
      ClaArea:props.ClaArea, 
      ClaMaquina:props.ClaMaquina, 
      Fecha:props.Fecha
    }; 
    callApi(urlWebService, 'POST', paramsService, (response) => {  
      setIsLoading(false);
      setDetalle(response.data.ProduccionSeguimientoTendenciaDiariaDetalle);
      setDowntime(response.data.ProduccionSeguimientoTendenciaDiariaDowntime);
      setComentarios(response.data.ProduccionSeguimientoTendenciaDiariaComentario);
    }, null, false, false);
  }
 
  return (
    <> 
      <div> 
        {IsLoading ? (
          <DailyOperationProductionTrendSkeleton />
        ) : (
          <>
            <Row className='pb-2'>
              <Col>
                <h2>{t('wtw:DetailByItem')}</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <FieldDataTable 
                  value={Detalle}    
                  paginator={false}
                > 
                  {props.Fecha === null && <Column header={t('wtw:Date')} className='text-left' headerStyle={{ width: '120px' }} body={(rowData)=> DateTemplate (rowData, 'Fecha')}></Column>}                
                  <Column field="NomTurno" header={t('wtw:Shift')} className='text-center' headerStyle={{ width: '100px' }} columnKey="NomTurno"></Column> 
                  <Column field="NomArticulo" header={t('wtw:Item')} body={(rowData)=> NomArticuloTemplate (rowData, 'NomArticulo')} headerStyle={{ width: '300px' }} columnKey="NomArticulo"></Column> 
                  <Column header={t('wtw:UnitsByHr')} className='text-center' headerStyle={{ width: '90px' }} body={(rowData)=> DecimalTemplate (rowData, 'UnitsByHr', 2)}></Column>
                  <Column header={t('wtw:Utilizacion')} className='text-right' headerStyle={{ width: '80px' }} body={(rowData)=> PorcentajeTemplate (rowData, 'Utilizacion')}></Column> 
                  <Column header={t('wtw:Efficiencia')} className='text-right' headerStyle={{ width: '80px' }} body={(rowData)=> PorcentajeTemplate (rowData, 'Efficiencia')}></Column>
                  <Column header={t('wtw:Scrap')} className='text-right' headerStyle={{ width: '90px' }} body={(rowData)=> PorcentajeTemplate (rowData, 'Scrap')}></Column>
                  <Column header={t('wtw:OEE')} className='text-right' headerStyle={{ width: '80px' }} body={(rowData)=> PorcentajeTemplate (rowData, 'OEE')}></Column>
                  <Column header={t('wtw:Tons')} className='text-right' headerStyle={{ width: '90px' }} body={(rowData)=> DecimalTemplate (rowData, 'Tons', 2)}></Column>
                  <Column header={t('wtw:Units')} className='text-right' headerStyle={{ width: '90px' }} body={(rowData)=> CajasTemplate (rowData, 'Cajas', 0)}></Column>
                  <Column header={t('wtw:Disponibilidad')} className='text-right' headerStyle={{ width: '80px' }} body={(rowData)=> PorcentajeTemplate (rowData, 'Disponibilidad')}></Column>
                </FieldDataTable>
              </Col> 
            </Row> 
            <Row>
              <Col>
                <h2>{t('wtw:Downtimes')}</h2>
              </Col>
            </Row>
            <Row className='pb-2'>
              <Col>
                <FieldDataTable 
                  value={Downtime}    
                  paginator={false}
                >  
                  {props.Fecha === null && <Column header={t('wtw:Date')} className='text-left' headerStyle={{ width: '120px' }} body={(rowData)=> DateTemplate (rowData, 'Fecha')}></Column>}    
                  <Column field="NomTurno" header={t('wtw:Shift')} className='text-center' headerStyle={{ width: '100px' }} columnKey="NomTurno"></Column> 
                  <Column field="NomGpoParo" header={t('wtw:Group')} headerStyle={{ width: '300px' }} columnKey="NomGpoParo"></Column> 
                  <Column field="NomSeccionParo" header={t('wtw:Type')} headerStyle={{ width: '300px' }} columnKey="NomSeccionParo"></Column> 
                  <Column field="HorasParo" header={t('wtw:Hours')} headerStyle={{ width: '100px' }} body={(rowData)=> HorasParoTemplate (rowData)} columnKey="HorasParo"></Column> 
                </FieldDataTable>
              </Col> 
            </Row> 
            <Row>
              <Col>
                <h2>{t('wtw:DailyCommentsDetail')}</h2>
              </Col>
            </Row>
            <Row className='pb-2'>
              <Col>
                <FieldDataTable 
                  value={Comentarios}    
                  paginator={false}
                > 
                  {props.Fecha === null && <Column header={t('wtw:Date')} className='text-left' headerStyle={{ width: '120px' }} body={(rowData)=> DateTemplate (rowData, 'Fecha')}></Column>}    
                  <Column field="NomTurno" header={t('wtw:Shift')} className='text-center' headerStyle={{ width: '100px' }} columnKey="NomTurno"></Column> 
                  <Column field="ExplicacionProblema" header={t('wtw:Problem')} headerStyle={{ width: '350px' }} columnKey="ExplicacionProblema"></Column> 
                  <Column field="PosibleSolucionProblema" header={t('wtw:Solution')} headerStyle={{ width: '350px' }} columnKey="PosibleSolucionProblema"></Column> 
                </FieldDataTable>
              </Col> 
            </Row>
          </>
        )}
      </div>  
    </>
  );
};

export default MonthlyProductionSummaryDetail;

