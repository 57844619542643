/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'; 
import { useTranslation } from 'react-i18next'; 
import PageContent from '../../../../layouts/PageContent';  
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';   
import InstructionsCard from '../../../../components/Cards/InstructionsCard/InstructionsCard';
import FieldTextArea  from '../../../../components/Controls/FieldTextArea.jsx';
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import { Divider } from 'primereact/divider';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';  
import { ConstantLineStyle } from 'devextreme-react/polar-chart'; 
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator'; 
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from '../../../../components/SweatAlert/SweatAlertCustomIcon'
import moment from "moment";
import FieldDropdown from 'components/Controls/FieldDropdown';
import ImagePotentialPerformance from '../../../../assets/img/PotentialPerformance.png';
import FieldNumber from 'components/Controls/FieldNumber';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';

const ManagerReview = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);  
  
  const defaultAnio = props.Anio  !== undefined ? props.Anio : null;
  const defaultAnioCriteria = 2024;
  const defaultClaUsuario = props.ClaUsuario  !== undefined ? props.ClaUsuario : null;

  const usuarioConectado = getSessionItem('ClaUsuarioMod', 0);
    
  const [selfReviewFeedback, setSelfReviewFeedback] = useState([]); 
  const [SelfReviewCriteria, setSelfReviewCriteria] = useState([]);
  const [SelfReviewCriteriaScale, setSelfReviewCriteriaScale] = useState([]);
  const [commentAvailable, setCommentAvailable] = useState(false);
  const [selfReviewAvailable, setSelfReviewAvailable] = useState(false); 
  const [esInformacionDisponible, setEsInformacionDisponible] = useState(false);
  const [estatusManagerReview, setEstatusManagerReview] = useState(0); 
  const [Comentarios, setComentarios] = useState(null);  
  const [esTienePermisoEdicion, setEsTienePermisoEdicion] = useState(0);  
  const [errors, setErrors] = useState({}); 
  const [idPerformanceDev, setIdPerformanceDev] = useState(null);
  const [FechaIniciaManagerReview, setFechaIniciaManagerReview] = useState(null);
  const [FechaTerminaManagerReview, setFechaTerminaManagerReview] = useState(null);
  const [FechaTerminaAnnualReview, setFechaTerminaAnnualReview] = useState(null);
  const [EsDesactivaUpdateButton, setEsDesactivaUpdateButton] = useState(0);
  const [EsVersionSoloLectura, setEsVersionSoloLectura] = useState(false);
  const [EstatusAnnualReview, setEstatusAnnualReview] = useState(0);
  const [Encabezado, setEncabezado] = useState(0);
  const [ClaPotentialPerformance, setClaPotentialPerformance] = useState(null);
  const [OpcionesPotentialPerformance, setOpcionesPotentialPerformance] = useState([]);
  const [AvgTotal, setAvgTotal] = useState(0);
  const [EmpleadoDesempenioAreasOportunidad, setEmpleadoDesempenioAreasOportunidad] = useState(null);

  useEffect(() => {     
    loadManagerReview();    
  }, [props.Anio, props.ClaUsuario]); 

  const loadManagerReview = () => { 
    const GetManagerReviewUser = `${config.UrlApiProject}hr/GetManagerReviewUser`;  
    const paramsToService = {  
      ClaUsuario: defaultClaUsuario,
      Anio: defaultAnio
    };     

    callApi(GetManagerReviewUser, 'POST', paramsToService, (response) => { 
          
        setSelfReviewFeedback(response.data.ManagerReview);  

        setSelfReviewAvailable(response.data.ManagerReview.length>0); 
        setSelfReviewCriteria(response.data.ManagerReviewCriteria);
 
        
        setOpcionesPotentialPerformance(response.data.OpcionesPotentialPerformance); 
        if (response.data.Encabezado.length>0){
          setAvgTotal(obtienePromedio(response.data.ManagerReviewCriteria));
          setEmpleadoDesempenioAreasOportunidad(response.data.Encabezado[0].EmpleadoDesempenioAreasOportunidad);
          setComentarios(response.data.Encabezado[0].Comentarios);
          setCommentAvailable(response.data.Encabezado[0].EstatusManagerReview === 1); 
          setEsInformacionDisponible(response.data.Encabezado[0].ActivaManagerReview);
          setEstatusManagerReview(response.data.Encabezado[0].EstatusManagerReview);
          setIdPerformanceDev(response.data.Encabezado[0].IdPerformanceDev);
          setEsTienePermisoEdicion (response.data.Encabezado[0].ClaUsuarioManagerPerformanceDev === usuarioConectado);
          setEstatusAnnualReview(response.data.Encabezado[0].EstatusAnnualReview);
          setClaPotentialPerformance(response.data.Encabezado[0].ClaPotentialPerformance);
          setEncabezado(response.data.Encabezado[0]); 
        } 
        
        setErrors({});

        // varificamos si es solo lectura....
        if (typeof(props.ReadOnlyView) !== 'undefined')
        {
          setEsVersionSoloLectura(props.ReadOnlyView);
        } 
        
        setSelfReviewCriteriaScale([
        {
            Scale: t('hr:ScaleValueOutstanding'),
            PerformanceRating: t('hr:ScaleOutstanding')
        },
        {
          Scale: t('hr:ScaleValueExceeds'),
          PerformanceRating: t('hr:ScaleExceeds')
        },
        {
          Scale: t('hr:ScaleValueMeets'),
          PerformanceRating: t('hr:ScaleMeets')
        },
        {
          Scale: t('hr:ScaleValueNeedsImprovement'),
          PerformanceRating: t('hr:ScaleNeedsImprovement')
        },
        {
          Scale: t('hr:ScaleValueUnsatisfactory'),
          PerformanceRating: t('hr:ScaleUnsatisfactory')
      }
    ]);

    });
  }

  const onChangeComentarios = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Comentarios;   
    setErrors(newErrors) 
    setComentarios(e.target.value)
  }

  const onChangeRespuesta = (e, index) => {   

    const updatedSelfReviewFeedback = [...selfReviewFeedback];
    updatedSelfReviewFeedback[index].RespuestaPregunta = e.target.value; 

    if (e.target.value.length > 0){ 
        
        updatedSelfReviewFeedback[index].errors = null;
        const newErrors = { ...errors }  
        const field = { ...newErrors[`selfReviewFeedback[${index}]`] };
        field.RespuestaPregunta = null;   
        newErrors[`selfReviewFeedback[${index}]`] = field;   
        setErrors(newErrors) ;  
    }

    setSelfReviewAvailable(updatedSelfReviewFeedback); 
  }
  
  const valitationScheme = yup.object().shape({
    selfReviewFeedback: yup.array().of(
        yup.object().shape({
            RespuestaPregunta: yup.string().trim().required(t('common:RequiredField')).nullable() 
      })),
      SelfReviewCriteria: (
        defaultAnio>=defaultAnioCriteria
        ?  yup.array().of(
          yup.object().shape({
            CalificacionManager: yup.mixed().required(t('common:RequiredField')).nullable(),
          })
        )
        : yup.array().of(
          yup.object().shape({
            CalificacionManager: yup.mixed().nullable(),
          })
        )
      ), 
      EmpleadoDesempenioAreasOportunidad: yup.string().trim().nullable()
        .when([], {
          is: () => defaultAnio >= defaultAnioCriteria,
          then: schema => schema.required(t('common:RequiredField'))
        })
      // ,ClaPotentialPerformance: yup.mixed().required(t('common:RequiredField')).nullable() 
  }); 

  const valitationSchemeComentarios = yup.object().shape({
    Comentarios: yup.string().trim().required(t('common:RequiredField')).nullable()
  }); 

  const confirmationMessageHTML = ( 
    <div>
      <SweatAlertCustomIcon title={t('common:SubmitConfirmation')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
      <div>{t('hr:SubmitResponseConfirmation')}. <b>{t('common:ThanksParticipation')}</b></div> 
    </div>  
   )
   
   
  
  const onConfirmSubmit = async () => {  
    const value = {
      selfReviewFeedback,
      SelfReviewCriteria,
      EmpleadoDesempenioAreasOportunidad
  }

  const validator = new YupValidator(valitationScheme);
  const esValido = await validator.validate(value);
   
  if (!esValido){ 
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      setErrors(validator.errors); 
      return;
  }
  

    swal({ 
      buttons: {
        cancelar: {
          text: "Cancel",
          value: "cancel",
          className: "rounded-button danger"
        },
        continuar: {
          text: "Continue",
          value: "continue",
          className: "rounded-button light-green"
        }
      },
      content: confirmationMessageHTML
    }).then((value) => {
      switch (value) { 
        case "continue":
          // run function
          onSubmit();
          break; 
        default:
          break;
      }
    });

  }
  

  const onSave =   () => { 
    
    const paramsService = {
      IdPerformanceDev: idPerformanceDev,
      Anio : defaultAnio
     ,ClaUsuarioPerformanceDev : defaultClaUsuario 
     ,ComentariosManagerReview: Comentarios   
     ,PerformanceDevQuestionarioResuelto:selfReviewFeedback 
     ,EstatusManagerReview: 0
     ,ClaPotentialPerformance
     ,PerformanceDevCriterioCalificacion: SelfReviewCriteria
     ,EmpleadoDesempenioAreasOportunidad
   };  
     
   const SaveManagerReview = `${config.UrlApiProject}HR/SaveManagerReview`;

   callApi(SaveManagerReview, 'POST', paramsService, (response) => { 
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      loadManagerReview();  
   });   
  }
  
  const onUpdateClick =  async () => { 
      
    const value = {
        Comentarios 
    }

    const validator = new YupValidator(valitationSchemeComentarios);
    const esValido = await validator.validate(value);
    
    console.log(validator.errors);
    if (!esValido){ 
        setErrors(validator.errors); 
        return;
    }
    
    const paramsService = {
      IdPerformanceDev: idPerformanceDev,
      Anio : defaultAnio
     ,ClaUsuarioPerformanceDev : defaultClaUsuario 
     ,ComentariosManagerReview: Comentarios   
   };  
    

   const SaveManagerReview = `${config.UrlApiProject}HR/UpdateManagerReviewComments`;
 
   callApi(SaveManagerReview, 'POST', paramsService, (response) => {  
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      loadManagerReview();  
   }); 
  }

  const onChangeClaPotentialPerformance = (value) => {  
    const newErrors = { ...errors }
    delete newErrors.ClaPotentialPerformance;   
    setErrors(newErrors)
    setClaPotentialPerformance(value)
  } 
   
  const onSubmit = async () => { 
     
    const value = {
        selfReviewFeedback,
        SelfReviewCriteria,
        EmpleadoDesempenioAreasOportunidad
        // ,ClaPotentialPerformance 
    }

    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);
     
    if (!esValido){ 
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
        setErrors(validator.errors); 
        return;
    }
    
    const paramsService = {
      IdPerformanceDev: idPerformanceDev,
      Anio : defaultAnio
     ,ClaUsuarioPerformanceDev : defaultClaUsuario 
     ,ComentariosManagerReview: Comentarios   
     ,PerformanceDevQuestionarioResuelto:selfReviewFeedback 
     ,EstatusManagerReview: 1
     ,ClaPotentialPerformance
     ,PerformanceDevCriterioCalificacion: SelfReviewCriteria
     ,EmpleadoDesempenioAreasOportunidad
   };   
 
   const SaveManagerReview = `${config.UrlApiProject}HR/SaveManagerReview`;

   callApi(SaveManagerReview, 'POST', paramsService, (response) => { 
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      loadManagerReview();  
   });   
  }

  const fieldsRequired = (rowData) =>  { 
    return (
      <>
        {errors[`SelfReviewCriteria[${rowData.RowIndex}]`] != null ? (<i className="fas fa-exclamation-triangle invalid-color" title={t('hr:ManagerSelfReviewCriteriaRequired')}></i>) : ""}
      </>
    );
  } 

  const calificacionManagerByEditor = (props, rowData) => {
    return (
      <div className="p-fluid">
        <FieldNumber   
          mode="decimal"
          locale="en-US"
          value={props.rowData.CalificacionManager} 
          minFractionDigits={1}
          maxFractionDigits={1}
          min={0}
          max={5}
          onChange={(e) => onCalificacionManagerByChange(props, e.target.value, rowData)}
        />  
      </div>
      
    );
  }
  
  const onCalificacionManagerByChange = (props, value ) => {  
    const updatedRows = [...SelfReviewCriteria];
    updatedRows[props.rowIndex].CalificacionManager = value;

    updatedRows[props.rowIndex].errors = null;
    const newErrors = { ...errors };
    const fieldKey = `SelfReviewCriteria[${props.rowIndex}]`;
    if (value) {
      if (newErrors[fieldKey]) {
        delete newErrors[fieldKey].CalificacionManager; 
        if (Object.keys(newErrors[fieldKey]).length === 0) {
          delete newErrors[fieldKey];
        }
      }
    } else { 
      if (!newErrors[fieldKey]) {
        newErrors[fieldKey] = {};
      }
      newErrors[fieldKey].CalificacionManager = null;
    }
    setErrors(newErrors);
    setSelfReviewCriteria(updatedRows); 
    setAvgTotal(obtienePromedio(updatedRows));
  }; 

  

  function obtienePromedio(lSelfReviewCriteria) {
    const calificaciones = lSelfReviewCriteria
        .map(row => row.CalificacionManager)
        .filter(val => val !== null && val !== undefined && !isNaN(val));
  
    if (calificaciones.length === 0) {
        return 0;  
    }
    const nAvgTotal = calificaciones.reduce((acc, curr) => acc + curr, 0) / calificaciones.length;
    const roundedAvgTotal = parseFloat(nAvgTotal.toFixed(2));
    return roundedAvgTotal;
  }

  const onChangeEmpleadoDesempenioAreasOportunidad = (e) => {
    const newErrors = { ...errors };
    delete newErrors.EmpleadoDesempenioAreasOportunidad;
    setErrors(newErrors);
    setEmpleadoDesempenioAreasOportunidad(e.target.value);
  };

  if(!esInformacionDisponible){
    return (
      <>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-center p-7">  
            <h2>{t('hr:ManagerReviewPeriodClosed')}</h2> 
          </Col>
        </Row>
      </> 
    );
  } 

  if(estatusManagerReview === 0 && !esTienePermisoEdicion){
    return (
      <>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-center p-7">  
            <h2>{t('hr:ManagerReviewInstructionsBeforeSubmitManager')}</h2> 
          </Col>
        </Row>
      </> 
    );
  } 




  return (
    <>        
      {EsVersionSoloLectura?<></>: (
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>   
            <InstructionsCard>
              {
            esTienePermisoEdicion ? 
              estatusManagerReview === 0 ? (
                <p>
                  {t('hr:ManagerReviewInstructionsBeforeSubmit')} <b className="highlight-text"> { moment(Encabezado.FechaIniciaManagerReview).format("LL") } - { moment(Encabezado.FechaTerminaManagerReview).format("LL") }</b>.
                </p>
                        ) : 
                <p>{t('hr:ManagerReviewInstructionsAfterSubmit')}.</p> 
              
              : 
              estatusManagerReview === 0 ? (
                <p> 
                  {t('hr:ManagerReviewInstructionsBeforeSubmitManager')}.
                </p>
              ) : <p>{t('hr:ManagerReviewInstructionsAfterSubmitManager')}.</p> 
            }
            </InstructionsCard>
          </Col>
        </Row>
        
       )}
      {
        esTienePermisoEdicion &&
        (
          <>
            <Row id="ManagerRating" hidden={defaultAnio < defaultAnioCriteria}>
              <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
              </Col>
            </Row>
            <Row id="ManagerRating" hidden={defaultAnio < defaultAnioCriteria}>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Card> 
                  <CardBody className="">
                    <Row className="align-items-start">
                      <Col lg={8} md={8} sm={12} xs={12} className="text-center"> 
                        <div>
                          <h1>{t('hr:ManagerPerformanceRating')}</h1>
                        </div> 
                      </Col>
                      <Col lg={4} md={4} sm={12} xs={12} className="text-center"> 
                        <div>
                          <h1>{t('hr:PerformanceRatingScale')}</h1>
                        </div> 
                      </Col>
                    </Row>
                    <Row className="align-items-start h-100">  
                      <Col lg={8} md={8} sm={12} xs={12} className="h-100">  
                        <FieldDataTable 
                          value={SelfReviewCriteria} 
                          editMode="cell" 
                          className="editable-cells-table"
                          paginator={false}
                        > 
                          <Column body={fieldsRequired} headerStyle={{ width: '20px' }}></Column>
                          <Column 
                            field="TituloCriterio" 
                            columnKey="TituloCriterio" 
                            header={t('hr:PerformanceCriteria')} 
                            headerStyle={{ width: '70%' }}   
                            footer={t('hr:Total')}
                          >
                          </Column> 
                          <Column
                            field="CalificacionManager" 
                            header={t('hr:ManagerRating')} 
                            headerStyle={{ width: '30%', textAlign: 'center' }} 
                            style={{ textAlign: 'center' }} 
                            editor={estatusManagerReview > 0 ? null : (props) => calificacionManagerByEditor(props)} 
                            footer={AvgTotal}
                          >
                          </Column>   
                        </FieldDataTable>
                      </Col>  
                      <Col lg={4} md={4} sm={12} xs={12} className="h-100">  
                        <FieldDataTable 
                          value={SelfReviewCriteriaScale}  
                          paginator={false}
                          className='table-header-background-ligth-green'
                        >  
                          <Column 
                            field="Scale" 
                            columnKey="Scale" 
                            header={t('hr:Scale')} 
                            headerStyle={{ width: '20%',textAlign: 'center' }} 
                            style={{ textAlign: 'center' }} 
                            className="table-header-background-ligth-green"
                          >
                          </Column> 
                          <Column
                            field="PerformanceRating" 
                            columnKey="PerformanceRating" 
                            header={t('hr:PerformanceRating')} 
                            headerStyle={{ width: '80%'}}  
                            className="table-header-background-ligth-green"
                          >
                          </Column>   
                        </FieldDataTable>
                      </Col>  
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )
       }
      
      <Row id="ManagerPerformanceReview" hidden={defaultAnio < defaultAnioCriteria}>
        <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
        </Col>
      </Row>
      <Row id="ManagerPerformanceReview" hidden={defaultAnio < defaultAnioCriteria} className="align-items-start mb-4">
        <Col md={12} sm={12} xs={12}>
          <Card>
            <CardHeader className="light-blue">
              <CardTitle className="text-center p-2">{t('hr:ManagerPerformanceReview')}</CardTitle>
            </CardHeader>
            <CardBody className="questionario-viewer">
              <div>
                <div className="Pregunta">
                  <label>
                    <b>1.- {t('hr:ManagerPerformanceReviewQuestion1')} {defaultAnio} {t('hr:ManagerPerformanceReviewQuestion1-2')}</b>
                  </label>
                </div>  
                {estatusManagerReview === 1 || esTienePermisoEdicion === false || EsVersionSoloLectura === true ? (
                  <>  
                    <label>{EmpleadoDesempenioAreasOportunidad}</label> 
                  </>
                          )
                          : (
                            <>
                              <div className="p-fluid">
                                <FieldTextArea
                                  name="EmpleadoDesempenioAreasOportunidad"
                                  rows={3}
                                  placeholder=""
                                  value={EmpleadoDesempenioAreasOportunidad}
                                  maxLength={3000}
                                  onChange={onChangeEmpleadoDesempenioAreasOportunidad}
                                  errors={errors}
                                />
                              </div>
                            </>
                         )} 
              
              </div> 
            </CardBody>
          </Card>
        </Col>
       
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}> 
                &nbsp;
        </Col>
      </Row>
      <Row className="align-items-start mb-4">  
        <Col md={commentAvailable  ? 8 : 12} sm={12} xs={12} style={{ display: selfReviewAvailable ? "block" : "none"}}>  
          <Card> 
            <CardHeader className="light-blue">
              <CardTitle className="text-center p-2">{t('hr:ManagerReviewEmployeeDevelopment')}</CardTitle>
            </CardHeader>    
            <CardBody className="questionario-viewer"> 
              {
                esTienePermisoEdicion && estatusManagerReview === 0 && 1 === 2 &&
                (
                <>
                  <Row className='pt-3'>
                    <Col>
                      
                    </Col>
                  </Row>
                  <Row className='pt-3 pb-3 align-items-start'>
                    <Col className='text-center'>
                      <InstructionsCard>
                        <p>The 9-Box is a tool that is widely used to capture current perceptions related to employee development. The factors that are being considered are perceived performance and professional growth potential. We are implementing this tool to help managers guide performance and development conversations.</p>  
                        <p>Instructions: Please take some time and read the descriptions below to see which one aligns most closely with this employee. Once you have made your decision, please select the number that correlates with the description. Use this description as a guide for suggesting development actions to this employee in the questions below. There are more examples in the Manager Guide if needed.</p>
                      </InstructionsCard>
                      <img alt="..." src={ImagePotentialPerformance} style={{ width: '70%', height: '70%' }}  />
                    </Col>
                    <Col>
                      <div className="Pregunta">
                        <label><b>Please choose an option:</b></label>
                        
                      </div>
                      <div className="p-fluid">
                        <FieldDropdown  
                          name="ClaPotentialPerformance"  
                          value={ClaPotentialPerformance} 
                          options={OpcionesPotentialPerformance}  
                          optionValue="ClaPotentialPerformance"
                          optionLabel="NomPotentialPerformance" 
                          onChange={(e) => onChangeClaPotentialPerformance(e.value)} 
                          errors={errors}
                          disabled={EsVersionSoloLectura||estatusManagerReview}
                        />  
                      </div>

                      <div>
                        <div className='text-center pt-3'><b>Key</b></div>
                        <p>9 - Consistently surpasses performance expectations and is successful in their role.  Displays outstanding leadership, strategic thinking and problem-solving skills. Goes the extra mile to make the company successful and exhibits big picture thinking. </p>
                        <p>8 - Exceeds performance expectations and works with less effort than required. Is proactive and expresses a solid willingness to learn and grow. Shows high motivation to be the expert in their field.</p>
                        <p>7 - Reliable performance (results/quality of work) and displays independence, adaptability, and initiative. Is proactive and expresses an exceptional willingness to grow and advance. Goes the extra mile to improve processes and drive innovation.</p>
                        <p>6 - Exceeds performance expectations (results/quality of work) and works with less effort. Shows motivation to excel in their current role and are comfortable maintaining their acquired expertise.</p>
                        <p>5 - Reliable performance (results/quality of work) and shows work effort as expected. Is proactive and expresses a solid willingness to learn and grow. Occasionally goes above and beyond what is expected of them to improve processes.</p>
                        <p>4 - Has not achieved the performance expected for their role but shows extra effort to reach the expected performance. Is proactive and expresses a strong willingness to learn, grow and excel in their career.</p>  
                        <p>3 - Meets performance expectations (results/quality of work) and shows work effort as expected. Does not express the willingness or motivation to improve or grow professionally.</p>
                        <p>2 - Shows inconsistent performance (results/quality of work) and must work hard to achieve results. Must frequently check with manager for direction. Expresses a willingness to learn, grow and improve but has not created a definitive plan yet.</p>
                        <p>1 – Consistently performs below expectations (results/quality of work) and must work extra hard to achieve minimum satisfactory results. Must constantly check with manager to be sure work is performed as expected. Does not express a willingness to learn, grow and improve.</p>
                      </div>
                    </Col>
                  </Row>
                </>
                )
              }
                   
              {selfReviewFeedback.map((element, index) => {  
                    return (  
                      <div key={index}>
                        { index > 0 ? <Divider></Divider> : <></> }
                        <div className="Pregunta">
                          <label><b>{element.DescripcionPregunta}</b></label>
                        </div>
                        <div className="Respuesta">
                          {estatusManagerReview === 1 || esTienePermisoEdicion === false || EsVersionSoloLectura === true ? (
                            <>  
                              <label>{element.RespuestaPregunta}</label> 
                            </>
                          )
                          : (
                            <>
                              <div className="p-fluid"> 
                                <FieldTextArea 
                                  name="RespuestaPregunta"
                                  rows={3}
                                  placeholder=''
                                  value={element.RespuestaPregunta}
                                  maxLength={3000}
                                  onChange={(e) => { onChangeRespuesta(e, index)}} 
                                  errors={errors[`selfReviewFeedback[${index}]`]}
                                /> 
                              </div>
                            </>
                         )} 
                        </div>
                      </div>  
                    );
                })}    
 
              
              { EsVersionSoloLectura || EstatusAnnualReview === 1 ? <></> : (
                <div className="w-100 text-center" style={{display: esTienePermisoEdicion ? "block" : "none" }}>
                
                  { estatusManagerReview === 0 ? <FieldButton label={t('common:Save')} className="secondary no-border rounded-button p-3 pl-5 pr-5  font-weight-bold" onClick={onSave} />  : <></>}
                
                 &nbsp;
                  { 
                  estatusManagerReview === 0 ? <FieldButton label={t('common:Submit')} className="light-green no-border rounded-button p-3 pl-5 pr-5  font-weight-bold" onClick={onConfirmSubmit} /> 
                  : <></> 
                }
                
                </div>
            )}
            </CardBody>
          </Card>
        </Col>  
        <Col lg={4} md={4} sm={12} xs={12} className="text-center" style={{ display: commentAvailable ? "block" : "none"}}>  
          <Card> 
            <CardBody>
              <Row className="align-items-start">
                <Col lg={12} md={12} sm={12} xs={12}> 
                  <div>
                    <label><b>{t('hr:Comments')}</b></label> 
                  </div>  
                  {esTienePermisoEdicion && EsDesactivaUpdateButton === 0 && EsVersionSoloLectura === false && EstatusAnnualReview === 0 ? (
                    <div className="p-fluid">
                      <FieldTextArea 
                        name="Comentarios"
                        rows={8}
                        placeholder=''
                        value={Comentarios} 
                        onChange={onChangeComentarios}
                        errors={errors}
                      /> 
                    </div>
                  ) : <label className="text-left">{Comentarios}</label> }
                  { EsVersionSoloLectura || EstatusAnnualReview === 1 ? <></> : (
                    <div style={{ display: esTienePermisoEdicion && EsDesactivaUpdateButton === 0 ? "block" : "none"}}>
                      <FieldButton label={t('common:Update')} className="light-green no-border rounded-button p-3 pl-5 pr-5  font-weight-bold" onClick={onUpdateClick} /> 
                    </div>
                  )}
                </Col> 
              </Row>
            </CardBody>
          </Card>
        </Col> 
      </Row>  
    </>  
        ); 
};

export default ManagerReview;
